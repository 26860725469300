import React from 'react'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Toolbar,
  Typography,
  Paper,
  IconButton,
  TextField,
} from '@material-ui/core'
import AddBoxIcon from '@material-ui/icons/AddBox'
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/Check'
import styled from 'styled-components'
import { colors } from 'app/theme'
import { useDispatch, useSelector } from 'react-redux'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { updateOffice } from 'ducks/entitiesSlice'
import { saveOffice } from 'thunks/saveOffice'
import { createOffice } from 'thunks/createOffice'
import { deleteOffice } from 'thunks/deleteOffice'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { decodeHtml } from '../../utilTools/decodeHtml'

const Container = styled(Paper)`
  && {
    margin: 10px 0;
  }
`

const ToolbarHeader = styled(Toolbar)`
  && {
    justify-content: space-between;
    padding-right: 8px;
  }
`

const ActionCell = styled(TableCell)`
  && {
    width: 50px;
  }
  border: ${p => p.border};
`
const ControlDescription = styled(Typography)`
  && {
    color: ${p => p.disabled && colors.grey};
  }
`

const CustomTextField = styled(TextField)`
  && {
    margin-bottom: 10px;
    width: ${p => p.width};
  }
`

const OfficesToolBar = ({ isDisabled, description, handleRowAdd }) => {
  return (
    <>
      <ToolbarHeader>
        <ControlDescription variant='h6' disabled={isDisabled}>
          {description}
        </ControlDescription>
      </ToolbarHeader>
      <>
        <IconButton
          aria-label='Add'
          onClick={handleRowAdd}
          disabled={isDisabled}
        >
          <AddBoxIcon />
        </IconButton>
      </>
    </>
  )
}

const DeleteButton = ({ office, index, isDisabled }) => {
  const dispatch = useDispatch()
  return (
    <ActionCell align='center'>
      <IconButton
        disabled={isDisabled}
        aria-label={`Delete button ${index}`}
        onClick={() => {
          dispatch(deleteOffice(office))
        }}
      >
        <DeleteOutlineIcon />
      </IconButton>
    </ActionCell>
  )
}

const OfficesEditableCell = ({ edit, office, entityIndex }) => {
  const dispatch = useDispatch()

  if (edit) {
    return (
      <TableCell>
        <CustomTextField
          type='text'
          variant='outlined'
          onChange={e => {
            const updatedOffice = {
              ...office,
              officeName: e.target.value,
            }
            dispatch(updateOffice({ index: entityIndex, updatedOffice }))
          }}
          value={decodeHtml(office?.officeName)}
          autoComplete='off'
        />
      </TableCell>
    )
  }
  return (
    <TableCell>
      <Typography>{decodeHtml(office?.officeName)}</Typography>
    </TableCell>
  )
}

const OfficesEditableSelect = ({ edit, office, entityIndex }) => {
  const dispatch = useDispatch()
  const regions = useSelector(state => state.entities.regions)
  if (edit) {
    return (
      <TableCell>
        <Select
          onChange={e => {
            const updatedOffice = {
              ...office,
              regionID: e.target.value,
              regionName: regions[e.target.value].regionName,
            }
            dispatch(updateOffice({ index: entityIndex, updatedOffice }))
          }}
          value={office.regionID}
        >
          {Object.values(regions).length > 0 &&
            Object.values(regions)
              .sort((a, b) => {
                return a.regionName.toLowerCase() > b.regionName.toLowerCase()
                  ? 1
                  : -1
              })
              .map((region, index) => (
                <MenuItem value={region.regionID} key={index}>
                  {decodeHtml(region.regionName)}
                </MenuItem>
              ))}
        </Select>
      </TableCell>
    )
  }
  return (
    <TableCell>
      <Typography>{decodeHtml(office?.regionName)}</Typography>
    </TableCell>
  )
}

const EntityEditableRow = ({ office, index: entityIndex }) => {
  const dispatch = useDispatch()
  const dirty = office.dirty
  return (
    <TableRow>
      <DeleteButton
        office={office}
        index={entityIndex}
        isDisabled={office.hasUsers}
      />
      <OfficesEditableCell
        edit={dirty}
        office={office}
        entityIndex={entityIndex}
        key={`entity-table-cell-first-name-${entityIndex}`}
      />
      <OfficesEditableSelect
        edit={dirty}
        office={office}
        entityIndex={entityIndex}
        key={`entity-table-cell-region-${entityIndex}`}
      />
      <ActionCell>
        {dirty === true ? (
          <IconButton
            aria-label={`Edit entity button ${entityIndex}`}
            onClick={() => {
              dispatch(saveOffice(office, entityIndex))
            }}
            disabled={false}
          >
            <CheckIcon />
          </IconButton>
        ) : (
          <IconButton
            aria-label={`Save entity button ${entityIndex}`}
            onClick={() => {
              let updatedOffice = { ...office, dirty: true }
              dispatch(updateOffice({ index: entityIndex, updatedOffice }))
            }}
            disabled={false}
          >
            <EditIcon />
          </IconButton>
        )}
      </ActionCell>
    </TableRow>
  )
}

const OfficesTableBody = ({ entities }) => {
  return (
    <TableBody>
      {Object.values(entities)
        .sort((a, b) => {
          return a.oldOfficeName.toLowerCase() > b.oldOfficeName.toLowerCase()
            ? 1
            : -1
        })
        .map((entity, entityIndex) => (
          <EntityEditableRow
            office={entity}
            index={entityIndex}
            key={`entity-table-row-${entityIndex}`}
          />
        ))}
    </TableBody>
  )
}

const OfficesTable = ({ isDisabled, description }) => {
  const dispatch = useDispatch()
  const regions = useSelector(state => state.entities.regions)
  const offices = useSelector(state => state.entities.offices)
  const handleRowAdd = () => {
    dispatch(createOffice())
  }
  return (
    <Container>
      <OfficesToolBar
        isDisabled={isDisabled || !regions || Object.keys(regions).length === 0}
        description={description}
        handleRowAdd={handleRowAdd}
      />
      <TableContainer>
        <Table aria-label='entity-table'>
          <TableHead>
            <TableRow>
              <TableCell>Action</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Region</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <OfficesTableBody entities={offices} />
        </Table>
      </TableContainer>
    </Container>
  )
}

export default OfficesTable
