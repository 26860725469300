import { createSlice } from '@reduxjs/toolkit'
import { storeInterviewData, clearInterviewData } from 'ducks/interviewSlice'
import { setUser } from 'ducks/userSlice'
import { signOut } from 'thunks/signOut'
import { setResourceTypes } from 'ducks/resourceSlice'

// This slice maintains a view of all of the app's
// 'readiness' values -- i.e. if something is still loading.
// This allows us to set all of our flags in one place so that
// we know where to find them. We also don't need to load full
// data sets to evaluate if they are loaded or not, which would
// be expensive.

const initialState = { interview: false, user: false, resourcesType: false }

const readySlice = createSlice({
  name: 'ready',
  initialState,
  reducers: {},
  extraReducers: {
    [setResourceTypes]: (state, action) => {
      state.resourcesType = true
    },
    [setUser]: (state, action) => {
      state.user = true
    },
    [storeInterviewData]: (state, action) => {
      state.interview = true
    },
    [clearInterviewData]: (state, action) => {
      state.interview = false
    },
    [signOut]: (state, action) => initialState,
  },
})

export default readySlice.reducer
