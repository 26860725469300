import React from 'react'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Toolbar,
  Typography,
  Paper,
  IconButton,
  TextField,
} from '@material-ui/core'
import AddBoxIcon from '@material-ui/icons/AddBox'
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/Check'
import styled from 'styled-components'
import { colors } from 'app/theme'
import { useDispatch, useSelector } from 'react-redux'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { updateRegion } from 'ducks/entitiesSlice'
import { saveRegion } from 'thunks/saveRegion'
import { createRegion } from 'thunks/createRegion'
import { deleteRegion } from 'thunks/deleteRegion'
import { decodeHtml } from '../../utilTools/decodeHtml'

const Container = styled(Paper)`
  && {
    margin: 20px 0;
  }
`

const ToolbarHeader = styled(Toolbar)`
  && {
    justify-content: space-between;
    padding-right: 8px;
  }
`

const ActionCell = styled(TableCell)`
  && {
    width: 50px;
  }
  border: ${p => p.border};
`
const ControlDescription = styled(Typography)`
  && {
    color: ${p => p.disabled && colors.grey};
  }
`

const CustomTextField = styled(TextField)`
  && {
    margin-bottom: 10px;
    width: ${p => p.width};
  }
`

const RegionsToolBar = ({ isDisabled, description, handleRowAdd }) => {
  return (
    <>
      <ToolbarHeader>
        <ControlDescription variant='h6' disabled={isDisabled}>
          {description}
        </ControlDescription>
      </ToolbarHeader>
      <>
        <IconButton
          aria-label='Add'
          onClick={handleRowAdd}
          disabled={isDisabled}
        >
          <AddBoxIcon />
        </IconButton>
      </>
    </>
  )
}

const DeleteButton = ({ region, index, isDisabled }) => {
  const dispatch = useDispatch()
  return (
    <ActionCell align='center'>
      <IconButton
        disabled={isDisabled}
        aria-label={`Delete button ${index}`}
        onClick={() => {
          dispatch(deleteRegion(region))
        }}
      >
        <DeleteOutlineIcon />
      </IconButton>
    </ActionCell>
  )
}

const RegionsEditableCell = ({ edit, region, entityIndex }) => {
  const dispatch = useDispatch()

  if (edit) {
    return (
      <TableCell>
        <CustomTextField
          type='text'
          variant='outlined'
          onChange={e => {
            const updatedRegion = {
              ...region,
              regionName: e.target.value,
            }
            dispatch(updateRegion({ index: entityIndex, updatedRegion }))
          }}
          value={decodeHtml(region?.regionName)}
          autoComplete='off'
        />
      </TableCell>
    )
  }
  return (
    <TableCell>
      <Typography>{decodeHtml(region?.regionName)}</Typography>
    </TableCell>
  )
}

const EntityEditableRow = ({ region, index: entityIndex }) => {
  const dispatch = useDispatch()
  const dirty = region.dirty
  return (
    <TableRow>
      <DeleteButton
        region={region}
        index={entityIndex}
        isDisabled={region.hasUsers || region.hasOffices}
      />
      <RegionsEditableCell
        edit={dirty}
        region={region}
        entityIndex={entityIndex}
        key={`entity-table-cell-first-name-${entityIndex}`}
      />
      <ActionCell>
        {dirty === true ? (
          <IconButton
            aria-label={`Edit entity button ${entityIndex}`}
            onClick={() => {
              dispatch(saveRegion(region, entityIndex))
            }}
            disabled={false}
          >
            <CheckIcon />
          </IconButton>
        ) : (
          <IconButton
            aria-label={`Save entity button ${entityIndex}`}
            onClick={() => {
              let updatedRegion = { ...region, dirty: true }
              dispatch(updateRegion({ index: entityIndex, updatedRegion }))
            }}
            disabled={false}
          >
            <EditIcon />
          </IconButton>
        )}
      </ActionCell>
    </TableRow>
  )
}

const RegionsTableBody = ({ entities }) => {
  return (
    <TableBody>
      {Object.values(entities)
        .sort((a, b) => {
          return a.oldRegionName.toLowerCase() > b.oldRegionName.toLowerCase()
            ? 1
            : -1
        })
        .map((entity, entityIndex) => (
          <EntityEditableRow
            region={entity}
            index={entityIndex}
            key={`entity-table-row-${entityIndex}`}
          />
        ))}
    </TableBody>
  )
}

const RegionsTable = ({ isDisabled, description }) => {
  const dispatch = useDispatch()
  const regions = useSelector(state => state.entities.regions)
  const handleRowAdd = () => {
    dispatch(createRegion())
  }

  return (
    <Container>
      <RegionsToolBar
        isDisabled={isDisabled}
        description={description}
        handleRowAdd={handleRowAdd}
      />
      <TableContainer>
        <Table aria-label='entity-table'>
          <TableHead>
            <TableRow>
              <TableCell>Action</TableCell>
              <TableCell>Name</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <RegionsTableBody entities={regions} />
        </Table>
      </TableContainer>
    </Container>
  )
}

export default RegionsTable
