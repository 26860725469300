import React from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import { setNewUser } from 'ducks/adminSlice'
import {
  addValidationError,
  removeValidationError,
} from 'ducks/validationErrorsSlice'
import { emailValidation } from 'thunks/validation'
import { decodeHtml } from '../../utilTools/decodeHtml'

const InputContainer = styled.div`
  margin-left: 8px;
  display: inline-block;
`

const CustomTextField = styled(TextField)`
  && {
    width: ${p => p.width};
    margin-top: ${p => (p.label ? '20px' : '3px')};
    > input: {
      color: white;
    }
    margin-right: 20px;
  }
`

const AdminText = React.memo(
  ({
    label,
    field,
    isRequired,
    width = '250px',
    validateEmail = false,
    isEdit = false,
  }) => {
    const value = useSelector(state => state.admin[field])
    const error = useSelector(state => state.validationErrors[field])
    const dispatch = useDispatch()

    const handleChange = event => {
      const isRequiredAndEmpty = isRequired && !event.target.value

      if (validateEmail) {
        dispatch(emailValidation(event.target.value, isEdit))
      } else if (isRequiredAndEmpty) {
        dispatch(
          addValidationError({
            admin: true,
            field,
            error: 'This field is required.',
          })
        )
      } else {
        dispatch(removeValidationError(field))
      }
      dispatch(setNewUser({ field: field, value: event.target.value }))
    }
    return (
      <InputContainer>
        <CustomTextField
          inputProps={{ 'aria-label': label }}
          value={decodeHtml(value) || ''}
          label={label}
          variant='outlined'
          type='text'
          error={error ? true : false}
          helperText={error}
          onChange={handleChange}
          onBlur={handleChange}
          width={width}
          autoComplete='off'
        />
      </InputContainer>
    )
  }
)

export default AdminText
