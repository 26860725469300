import React from 'react'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Select from 'components/general/Select'
import Text from 'components/general/Text'
import { useSelector } from 'react-redux'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import TableRowDescription from 'components/general/TableRowDescription'
import { previewInterviewSelector } from 'utilTools/reduxHelper'

const CustomTableContainer = styled(TableContainer)`
  && {
    margin: 10px 0 10px 0;
  }
`
const TableHeaderCell = styled(TableCell)`
  && {
    width: ${p => p.colwidth};
  }
`

const tableHeaders = [
  { name: '', colWidth: '30%' },
  { name: 'Yes/No', colWidth: '30%' },
  { name: 'When', colWidth: '20%' },
  { name: 'Explain Skills Learned', colWidth: '20%' },
]

export const tableQuestions = [
  { name: 'A. Classes for:' },
  {
    yesNo: 'ged_exm_class',
    when: 'ged_exm_when',
    explainSkillsLearned: 'ged_sk_lrnd',
  },
  {
    yesNo: 'bas_lit',
    when: 'bas_lit_when',
    explainSkillsLearned: 'bas_lit_sk_lrnd',
  },
  {
    yesNo: 'bas_math',
    when: 'basic_math_when',
    explainSkillsLearned: 'basic_math_explain_skills',
  },
  {
    yesNo: 'lmt_eng_prof',
    when: 'limited_english_when',
    explainSkillsLearned: 'limited_english_explain_skills',
  },
  {
    yesNo: 'b_cllg_clss',
    when: 'college_class_when',
    explainSkillsLearned: 'college_class_skills_learned',
  },
  {
    yesNo: 'c_jb_rdnss',
    when: 'job_readiness_when',
    explainSkillsLearned: 'job_readiness_skill_learned',
  },
  { name: 'D. Work Experience Programs:' },
  {
    yesNo: 'cmty_srvc',
    when: 'community_service_when',
    explainSkillsLearned: 'community_service_skill_learned',
  },
  {
    yesNo: 'job',
    when: 'job_when',
    explainSkillsLearned: 'job_explain_skill_learned',
  },
  {
    yesNo: 'intrnshp',
    when: 'internship_when',
    explainSkillsLearned: 'internship_skill_learned',
  },
  {
    yesNo: 'vol_wrk',
    when: 'volunteer_work_when',
    explainSkillsLearned: 'volunteer_work_explain_skill_learned',
  },
  {
    yesNo: 'wrk_std',
    when: 'work_study_when',
    explainSkillsLearned: 'work_study_explain_skill_learned',
  },
  {
    yesNo: 'e_mlt_srv_schl_trn',
    when: 'military_service_training_when',
    explainSkillsLearned: 'military_service_training_skill_learned',
  },
  {
    yesNo: 'f_othr',
    when: 'other_training_when',
    explainSkillsLearned: 'other_training_skill_learned',
  },
]

const OtherEducationTraining = React.memo(({ isPreview }) => {
  const selectorState = useSelector(previewInterviewSelector(isPreview));
  const responseIsYes = tableQuestions.map(question => {
    const answer = selectorState[question.yesNo]?.answer;
    return answer?.toUpperCase() === 'YS'
  })
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Other Education, Training, Certification'
          domain='Education'
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body2'>
          Instructions: Read each item aloud. For each "YES" response, specify
          skills learned and when.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h6'>
          Have you attended any other educational or training activities that we
          have not talked about, such as…?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <form>
          <CustomTableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {tableHeaders.map((header, index) => (
                    <TableHeaderCell key={index} colwidth={header.colWidth}>
                      {header.name}
                    </TableHeaderCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableQuestions.map((question, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        {question.yesNo ? (
                          <TableRowDescription
                            question_id={question.yesNo}
                            isPreview={isPreview}
                          ></TableRowDescription>
                        ) : (
                          question.name
                        )}
                      </TableCell>
                      <TableCell>
                        {question.yesNo && (
                          <Select
                            question_id={question.yesNo}
                            disableDescription={true}
                            isPreview={isPreview}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {question.when && (
                          <Text
                            question_id={question.when}
                            disableDescriptionLabel={true}
                            isDisabled={!responseIsYes[index]}
                            shouldDecode={true}
                            isPreview={isPreview}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {question.explainSkillsLearned && (
                          <Text
                            question_id={question.explainSkillsLearned}
                            disableDescriptionLabel={true}
                            isDisabled={!responseIsYes[index]}
                            shouldDecode={true}
                            isPreview={isPreview}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </CustomTableContainer>
        </form>
      </Grid>
    </Grid>
  )
})

export default OtherEducationTraining
