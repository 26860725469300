import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import TableHead from '@material-ui/core/TableHead'
import styled from 'styled-components'
import RecommendationSelectCell from './RecommendationSelectCell'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import { updateActionPlan } from 'ducks/unsavedActionPlansSlice'
import { deleteActionPlan } from 'thunks/deleteActionPlan'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import Can from 'app/Can'
import { cancelledOrCompletedSelector } from 'ducks/clientSlice'
import { colors } from 'app/theme'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'
import { decodeHtml } from '../../utilTools/decodeHtml'
import { openDialog } from 'ducks/dialogSlice'
import config from 'config'


const CustomTableContainer = styled(TableContainer)`
  && {
    margin: 10px 0 10px 0;
  }
`

const TableHeaderCell = styled(TableCell)`
  && {
    width: ${p => p.colwidth};
  }
`

const StyledDeleteButton = styled(IconButton)`
  float: right;
`

const StyledTextareaAutosize = styled(TextareaAutosize)`
  && {
    width: 100%;
    margin-top: ${p => (p.label ? '20px' : '3px')};
    &:focus {
      outline: 2px solid ${colors.saffron};
    }
    > input: {
      color: white;
    }
    margin-right: 20px;
    resize: none;
  }
`

const StyledWarningIcon = styled(WarningRoundedIcon)`
  && {
    vertical-align: middle;
    color: ${colors.saffron}
  }
`

const CharacterCountMessage = (props) => {
  return (
    <span>
      {props.valueLength === props.maxLength && <StyledWarningIcon/>} Characters Remaining: {props.maxLength - props.valueLength }
    </span>)
}


const StyledCharacterCountMessage = styled(CharacterCountMessage)`
  && {
    float: left;
  }
`

const StyledTextareaAutosizeLimit = (props) => {
  return (
    <>
      <StyledTextareaAutosize
        {...props}
      />
    </>
  )
}

const recommendationsSelector = state => state.recommendation.recommendations

const RecommendationsPage = React.memo(({ domainCode, subdomain, domain, isPreview }) => {
  const recommendations = useSelector(
    isPreview ? state => state.preview.recommendations : recommendationsSelector
  )
  const dispatch = useDispatch()
  const { roles } = useSelector(state => state.user)
  const cancelledOrCompletedInterview = useSelector(
    cancelledOrCompletedSelector
  )
  const { recommendationsLoading } = useSelector(
    isPreview ? state => state.preview : state => state.recommendation
  )

  if (recommendationsLoading) return <div>Loading recommendations...</div>

  const handleTextChange = (actionText, actionPlan, characterLimit, recommendation) => {
    if (characterLimit && actionText.length === characterLimit) {
        dispatch(openDialog({
          type: 'validation',
          title: 'Warning',
          description: 'There was a problem with the following category/categories.',
          props: { errors: [
            {
              id: recommendation.id,
              field: `${recommendation.category.description}`,
              value: `This action plan field(s) has reached the ${characterLimit}-character limit.`
            }
          ]}
        }))
    }

    if (cancelledOrCompletedInterview) return
    const newActionPlan = { ...actionPlan, actionText };
    dispatch(updateActionPlan(newActionPlan));
  }

  const handleDelete = actionPlan => {
    if (cancelledOrCompletedInterview) return
    dispatch(deleteActionPlan(actionPlan))
  }

  const generateRow = (
    recommendation,
    recommendationIndex,
    actionPlan,
    actionPlanIndex
  ) => {
    const characterLimit = config.maxActionPlanTextLength;
    return (
      <TableRow key={recommendationIndex + ' ' + actionPlanIndex}>
        <TableCell>
          <b>
            {actionPlanIndex === 0 ? `${decodeHtml(recommendation.englishCategoryText)} ` : null}
          </b>
          {actionPlanIndex === 0 ? decodeHtml(recommendation.englishTypeText) : null}
          <br />
        </TableCell>

        <TableCell
          dangerouslySetInnerHTML={{
            __html:
              actionPlanIndex === 0
                ? recommendation.englishRecommendationText
                : null,
          }}
        />
        <RecommendationSelectCell
          actionPlan={actionPlan}
          property='referralMade'
          label={decodeHtml(recommendation.englishCategoryText)}
          id={`${decodeHtml(recommendation.englishCategoryText)} referral made ${actionPlanIndex}`}
          firstActionPlan={0 === actionPlanIndex}
          isPreview={isPreview}
        ></RecommendationSelectCell>
        <RecommendationSelectCell
          actionPlan={actionPlan}
          property='accepted'
          id={`${decodeHtml(recommendation.englishCategoryText)} accepted ${actionPlanIndex}`}
          disableSelect={
            !actionPlan?.referralMade ||
            parseInt(actionPlan?.referralMade) !== 1
          }
          isPreview={isPreview}
        ></RecommendationSelectCell>
        <TableCell>
          <Can
            roles={roles}
            perform={isPreview ? 'BRE:view' : 'interview:edit'}
            no={() => {
              return (
                <StyledTextareaAutosizeLimit
                  aria-label='action plan'
                  disabled={true}
                  minRows={4}
                  value={decodeHtml(actionPlan?.actionText)}
                  onChange={e => handleTextChange(e.target.value, actionPlan, characterLimit, recommendation)}
                  maxLength={characterLimit}
                />
              )
            }}
            yes={() => {
              return (
                <StyledTextareaAutosizeLimit
                  aria-label='action plan'
                  disabled={cancelledOrCompletedInterview || isPreview}
                  minRows={4}
                  value={decodeHtml(actionPlan?.actionText)}
                  onChange={e => handleTextChange(e.target.value, actionPlan, characterLimit, recommendation)}
                  maxLength={characterLimit}
                />
              )
            }}
          />

          <div>
            <StyledCharacterCountMessage
              maxLength={characterLimit}
              valueLength={decodeHtml(actionPlan?.actionText)?.length ?? 0}
            />
            {actionPlanIndex > 0 ? (
            <Can
              roles={roles}
              perform={isPreview ? 'BRE:view' : 'interview:edit'}
              no={() => {
                return (
                  <StyledDeleteButton
                    disabled={true}
                    aria-label='delete'
                    size='small'
                    onClick={e => handleDelete(actionPlan)}
                  >
                    <DeleteIcon />
                  </StyledDeleteButton>
                )
              }}
              yes={() => {
                return (
                  <StyledDeleteButton
                    disabled={cancelledOrCompletedInterview || isPreview}
                    aria-label='delete'
                    size='small'
                    onClick={e => handleDelete(actionPlan)}
                  >
                    <DeleteIcon />
                  </StyledDeleteButton>
                )
              }}
            />
          ) : (
            <div></div>
          )}
          </div>

          
        </TableCell>
      </TableRow>
    )
  }

  return (
    <Grid container spacing={2}>
      <QuestionnaireHeader
        subdomain={subdomain}
        domain={domain}
        hideNotes={true}
      />

      <Grid item xs={12}>
        <CustomTableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeaderCell width='20%'>
                  Category <br /> Type
                </TableHeaderCell>
                <TableHeaderCell width='25%'>Recommendation</TableHeaderCell>
                <TableHeaderCell width='15%'>
                  Referral Made to Client?
                </TableHeaderCell>
                <TableHeaderCell width='15%'>
                  Client Accepted Referral?
                </TableHeaderCell>
                <TableHeaderCell width='25%'>Action Plan</TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(recommendations)
                .sort((a, b) => {
                  // If category matches, use type
                  if (
                    a.englishCategoryText.toLowerCase() ===
                    b.englishCategoryText.toLowerCase()
                  ) {
                    return a.englishTypeText.toLowerCase() >
                      b.englishTypeText.toLowerCase()
                      ? 1
                      : -1
                  }

                  // Else use description
                  return a.englishCategoryText.toLowerCase() >
                    b.englishCategoryText.toLowerCase()
                    ? 1
                    : -1
                })
                .map((recommendation, recommendationIndex) => {
                  // Filtering to only show 'Strengths' relevant recommendations
                  if (recommendation.domain.code !== domainCode) {
                    return null
                  }

                  if (
                    !recommendation.actionPlans ||
                    recommendation.actionPlans?.length === 0
                  ) {
                    return generateRow(
                      recommendation,
                      recommendationIndex,
                      null,
                      0
                    )
                  }

                  return recommendation.actionPlans?.map(
                    (actionPlan, actionPlanIndex) => {
                      return generateRow(
                        recommendation,
                        recommendationIndex,
                        actionPlan,
                        actionPlanIndex
                      )
                    }
                  )
                })}
            </TableBody>
          </Table>
        </CustomTableContainer>
      </Grid>
    </Grid>
  )
})

export default RecommendationsPage
