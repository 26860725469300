import debounce from 'lodash/debounce'
import store from 'app/store.js'
import { setActiveSession } from 'ducks/sessionSlice'

const events = [
  'DOMContentLoaded',
  'mousemove',
  'mousedown',
  'click',
  'scroll',
  'keypress',
  'wheel',
  'keydown',
  'DOMMouseScroll',
  'mouseWheel',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
  'visibilitychange',
]

// set session active at most every 30 seconds and at a minimum every minute. 
const debouncedHandler = debounce(event => {
  // this function is declared in the index file for application monitoring
  // eslint-disable-next-line no-undef
  ineum('reportEvent', 'sessionActive');    
  store.dispatch(setActiveSession()) // set active session to true when an event is triggered
}, 1000 * 30, { leading: true, trailing: false, maxWait: 1000 * 60 * 1 })

// Register event listeners for user activity
const activityListener = () => {
  events.forEach(event => {
    document.addEventListener(
      event,
      debouncedHandler
    )
  })
}

export default activityListener
