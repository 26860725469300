import React from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { previewInterviewSelector } from '../../utilTools/reduxHelper'

import styled from 'styled-components'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import Select from 'components/general/Select'
import Dropdown from 'components/general/Dropdown'
import QuestionnaireHeader from 'components/general/QuestionnaireHeader'
import TableRowDescription from 'components/general/TableRowDescription'

const CustomTableContainer = styled(TableContainer)`
  && {
    margin: 10px 0 10px 0;
  }
`
const TableHeaderCell = styled(TableCell)`
  && {
    width: ${p => p.colwidth};
  }
`

const tableHeaders = [
  { name: '', colWidth: '40%' },
  { name: 'Has this ever been a problem?', colWidth: '30%' },
  { name: 'Issue now? ', colWidth: '20%' },
]

const tableQuestions = [
  {
    problem: 'alc_3_prob',
    issueNow: 'alc_3_issue_now',
  },
  {
    problem: 'alc_4_prob',
    issueNow: 'alc_4_issue_now',
  },
  {
    problem: 'alc_5_prob',
    issueNow: 'alc_5_issue_now',
  },
  {
    problem: 'alc_6_prob',
    issueNow: 'alc_6_issue_now',
  },
  {
    problem: 'alc_7_prob',
    issueNow: 'alc_7_issue_now',
  },
  {
    problem: 'alc_8_prob',
    issueNow: 'alc_8_issue_now',
  },
  {
    problem: 'alc_9_prob',
    issueNow: 'alc_9_issue_now',
  },
  {
    problem: 'alc_10_prob',
    issueNow: 'alc_10_issue_now',
  },
  {
    problem: 'alc_11_prob',
    issueNow: 'alc_11_issue_now',
  },
  {
    problem: 'alc_12_prob',
    issueNow: 'alc_12_issue_now',
  },
  {
    problem: 'alc_13_prob',
    issueNow: 'alc_13_issue_now',
  },
  {
    problem: 'alc_14_prob',
    issueNow: 'alc_14_issue_now',
  },
  {
    problem: 'alc_15_prob',
    issueNow: 'alc_15_issue_now',
  },
  {
    descrRow: 'substance_dependancy',
    text: '16. Have you ever gotten help for:',
  },
  {
    problem: 'alc_a_alc_depend',
    issueNow: 'alc_a_issue_now',
  },
  {
    problem: 'alc_b_drg_depend',
    issueNow: 'alc_b_issue_now',
  },
  {
    problem: 'absd_presc_non_pres_prob',
    issueNow: 'absd_presc_non_pres_issue_now',
  },
]

const regularAlcoholConsumptionSelector = state =>
  state.interview.rg_drnk_alc.answer

  const regularAlcoholConsumptionPreviewSelector = state =>
  state.preview.rg_drnk_alc.answer

const medicationPrescribedSelector = state => state.interview.used_meds.answer
const medicationPrescribedPreviewSelector = state => state.preview.used_meds.answer

const selectRegularAlcoholConsumption = createSelector(
  regularAlcoholConsumptionSelector,
  answer => answer?.toUpperCase() === 'YS'
)

const selectRegularAlcoholConsumptionPreview = createSelector(
  regularAlcoholConsumptionPreviewSelector,
  answer => answer?.toUpperCase() === 'YS'
)

const selectMedicationPrescribed = createSelector(
  medicationPrescribedSelector,
  answer => answer?.toUpperCase() === 'YS'
)

const selectMedicationPrescribedPreview = createSelector(
  medicationPrescribedPreviewSelector,
  answer => answer?.toUpperCase() === 'YS'
)

const pickSelector = (isPreview, previewSelector, interviewSelector) => isPreview ? previewSelector : interviewSelector

const AlcoholAndDrugs = React.memo(({ isPreview }) => {
  const regularAlcoholConsumption = useSelector(pickSelector(isPreview, selectRegularAlcoholConsumptionPreview, selectRegularAlcoholConsumption))
  const medicationPrescribed = useSelector(pickSelector(isPreview, selectMedicationPrescribedPreview, selectMedicationPrescribed))
  const selectorState = useSelector(previewInterviewSelector(isPreview));
  // Create a selector for each question, and don't create selectors for decorative rows
  // const test = [];
  // for (let i = 0; i < tableQuestions.length; i++) {
  //   const question = tableQuestions[i];
  //   test[i] = question.problem 
  //     ? selectorState[question.problem].answer?.toUpperCase === 'NO'
  //     : null
  //   console.log(test[i])
  // }
  const substanceProblemSelected = tableQuestions.map((question, index) =>
    tableQuestions[index].problem
      ? selectorState[question.problem].answer?.toUpperCase() === 'NO'
      : null
  )
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <QuestionnaireHeader
          subdomain='Alcohol and Drugs'
          domain='Substance Use'
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h5'>Preamble</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h6'>
          Alcohol and drugs play a part in some people's lives. The next
          questions are about the role they played in your life in the past
          year.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <form>
          <Select question_id='rg_drnk_alc' isPreview={isPreview} />
          <Dropdown
            subquestion
            question_id='alc_how_often'
            isDisabled={!regularAlcoholConsumption}
            isPreview={isPreview}
          />
          <Dropdown
            subquestion
            question_id='alc_how_many'
            isDisabled={!regularAlcoholConsumption}
            isPreview={isPreview}
          />
          <Select question_id='used_meds' isPreview={isPreview} />
          <Dropdown
            subquestion
            question_id='meds_how_often'
            isDisabled={!medicationPrescribed}
            isPreview={isPreview}
          />
          <CustomTableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {tableHeaders.map((header, index) => (
                    <TableHeaderCell key={index} colwidth={header.colWidth}>
                      {header.name}
                    </TableHeaderCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableQuestions.map((question, index) =>
                  question.descrRow ? (
                    <TableRow key={index}>
                      <TableCell>
                        <span>{question.text}</span>
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ) : (
                    <TableRow key={index}>
                      <TableCell>
                        <TableRowDescription
                          question_id={question.problem}
                          isPreview={isPreview}
                        ></TableRowDescription>
                      </TableCell>
                      <TableCell>
                        <Select
                          question_id={question.problem}
                          disableDescription={true}
                          isPreview={isPreview}
                        />
                      </TableCell>
                      <TableCell>
                        <Select
                          question_id={question.issueNow}
                          disableDescription={true}
                          isDisabled={substanceProblemSelected[index]}
                          isPreview={isPreview}
                        />
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </CustomTableContainer>
          <Select question_id='sub_use_couns' isPreview={isPreview} />
        </form>
      </Grid>
    </Grid>
  )
})

export default AlcoholAndDrugs
