import React, { cloneElement } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import Table from '@material-ui/core/Table'
import TableContainer from '@material-ui/core/TableContainer'
import TableBody from '@material-ui/core/TableBody'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { captureFormData, capturePreviewFormData } from 'thunks/captureFormData'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import AddBoxIcon from '@material-ui/icons/AddBox'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import cloneDeep from 'lodash/cloneDeep'
import { colors } from 'app/theme'
import { cancelledOrCompletedSelector } from 'ducks/clientSlice'
import Can from 'app/Can'
import { previewCancelledOrCompletedSelector } from 'ducks/previewSlice'
import { decodeHtml } from 'utilTools/decodeHtml'

const Container = styled(Paper)`
  && {
    margin: 10px 0;
  }
`

const ToolbarHeader = styled(Toolbar)`
  && {
    justify-content: space-between;
    padding-right: 8px;
  }
`

const ActionCell = styled(TableCell)`
  && {
    width: 50px;
  }
  border: ${p => p.border};
`
const ControlDescription = styled(Typography)`
  && {
    color: ${p => p.disabled && colors.grey};
  }
`
const ControlTableHead = styled(TableHead)`
  && * {
    color: ${p => p.disabled && colors.grey};
  }
`

const AddButton = styled(IconButton)`
  && {
    padding: 6px;
  }
`

const IndicatorBox = styled(Box)`
  display: inline-flex;
  vertical-align: middle;
`

// createSelector for question data.
const data = (state, question_id) => state.interview[question_id]
const previewData = (state, question_id) => state.preview[question_id]
const createDataSelector = (isPreview) => {
  return createSelector((isPreview ? previewData : data), data => {
    return {
      // The table answers. An empty array if the answer is undefined.
      answers: data.answer ? data.answer : [],
      // Description for the question.
      description: data.description || '',
    }
  })
}

// Creates the header for each column in the table. The first column in the table will
// always be the "Actions" column.
const InfiniteTableHeader = React.memo(({ headers, isDisabled}) => {
  return (
    <ControlTableHead disabled={isDisabled}>
      <TableRow>
        {['Action', ...headers].map(subQuestion => {
          if (subQuestion === 'Action') {
            return (
              <ActionCell align='center' key={subQuestion}>
                {subQuestion}
              </ActionCell>
            )
          } else {
            return (
              <TableCell align='center' key={subQuestion}>
                {subQuestion}
              </TableCell>
            )
          }
        })}
      </TableRow>
    </ControlTableHead>
  )
})

// Creates the rows for the table. The row component that is passed in, is cloned and the
// following props are added:
//      - index (row index)
//      - rowAnswers (answers specific to the row)
//      - sendData (method for connecting to redux)
//      - question_id (the main question_id)

// The first cell in the row is always the remove button that deletes the row from the
// table.
const InfiniteTableBody = React.memo(
  ({ answers, question_id, rowComponent, isDisabled, isPreview }) => {
    const dispatch = useDispatch()

    const { roles } = useSelector(state => state.user)
    const cancelledOrCompletedInterview = useSelector(
      isPreview ? previewCancelledOrCompletedSelector : cancelledOrCompletedSelector
    )
    // Update and Delete Handlers
    const handleRowDelete = index => e => {
      if (cancelledOrCompletedInterview) return
      let updatedAnswers = [...answers]
      updatedAnswers.splice(index, 1)

      isPreview 
      ? dispatch(
        capturePreviewFormData({
          question_id: question_id,
          answer: updatedAnswers,
        })
      )
      : dispatch(
        captureFormData({
          question_id: question_id,
          answer: updatedAnswers,
        })
      )

    }
    const sendData = (index, sub_question_id, data) => {
      const updatedTableAnswers = cloneDeep(answers)
      updatedTableAnswers[index][sub_question_id] = data
      
      dispatch(captureFormData({ question_id, answer: updatedTableAnswers }))
    }

    return (
      <TableBody>
        {answers.length > 0 ? (
          answers.map((rowAnswers, index) => {
            const props = {
              index,
              rowAnswers,
              sendData,
              question_id,
            }
            const row = cloneElement(rowComponent, props)
            return (
              <TableRow key={index}>
                <ActionCell align='center'>
                  <Can
                    roles={roles}
                    perform={isPreview ? 'BRE:view' : 'interview:edit'}
                    yes={() => {
                      return (
                        <IconButton
                          aria-label={`Delete button ${index}`}
                          onClick={handleRowDelete(index)}
                          disabled={cancelledOrCompletedInterview || isDisabled}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      )
                    }}
                  />
                </ActionCell>
                {row}
              </TableRow>
            )
          })
        ) : (
          <TableRow key={1}>
            <ActionCell align='center' border='0'>
              <IconButton disabled={true} aria-label='Disabled Delete Button'>
                <DeleteOutlineIcon />
              </IconButton>
            </ActionCell>
          </TableRow>
        )}
      </TableBody>
    )
  }
)

const InfiniteTable = React.memo(
  ({
    question_id,
    headers,
    rowComponent,
    className,
    isDisabled,
    indicator,
    isPreview
  }) => {
    const dispatch = useDispatch()
    const dataSelector = createDataSelector(isPreview)
    const { roles } = useSelector(state => state.user)
    const cancelledOrCompletedInterview = useSelector(
      isPreview ? previewCancelledOrCompletedSelector : cancelledOrCompletedSelector
    )
    const { answers, description } = useSelector(state =>
      dataSelector(state, question_id)
    )

    // Action Handler
    const handleRowAdd = e => {
      isPreview 
      ? dispatch(
        capturePreviewFormData({
          question_id: question_id,
          answer: answers.concat({}),
        })
      )
      : dispatch(
        captureFormData({
          question_id: question_id,
          answer: answers.concat({}),
        })
      )
    }

    return (
      <Container className={className}>
        <ToolbarHeader>
          <ControlDescription
            variant='h6'
            disabled={cancelledOrCompletedInterview || isDisabled}
          >
            {decodeHtml(description)}
          </ControlDescription>
          <Can
            roles={roles}
            perform={isPreview ? 'BRE:view' : 'interview:edit'}
            no={() => {
              return (
                <Box>
                  <AddButton disabled={true}>
                    <AddBoxIcon titleAccess='Add' aria-label='Add' />
                  </AddButton>
                  <IndicatorBox>
                    {indicator}
                  </IndicatorBox>
                </Box>
              )
            }}
            yes={() => {
              return (
                <Box>
                  <AddButton
                    onClick={handleRowAdd}
                    disabled={cancelledOrCompletedInterview || isDisabled}
                  >
                    <AddBoxIcon titleAccess='Add' aria-label='Add' />
                  </AddButton>
                  <IndicatorBox>
                    {indicator}
                  </IndicatorBox>
                </Box>
              )
            }}
          />
        </ToolbarHeader>
        <TableContainer>
          <Table>
            <InfiniteTableHeader
              headers={headers}
              isDisabled={cancelledOrCompletedInterview || isDisabled}
            />
            <InfiniteTableBody
              answers={answers}
              question_id={question_id}
              rowComponent={rowComponent}
              isDisabled={cancelledOrCompletedInterview || isDisabled}
              isPreview={isPreview}
            />
          </Table>
        </TableContainer>
      </Container>
    )
  }
)

export default InfiniteTable
