import React, { useEffect, useState } from 'react'
import Chatbot from './Chatbot'
import CircularProgress from '@material-ui/core/CircularProgress'
import { IconButton } from '@material-ui/core'
import { gql } from 'apollo-boost'
import { useLazyQuery } from '@apollo/react-hooks'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { openDialog } from 'ducks/dialogSlice'
import { useDispatch, useSelector } from 'react-redux'
import { colors } from 'app/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCommentDots } from '@fortawesome/free-regular-svg-icons'

const GET_CHAT_TOKEN = gql`
  query {
    chatbotToken
  }
`

const useStyles = makeStyles(theme =>
  createStyles({
    chatTrigger: {
      position: 'absolute',
      bottom: 40,
      right: 40,
      color: colors.turquoise,
      fontSize: '5em',
    },
    chatContainer: {
      position: 'absolute',
      bottom: 40,
      right: 40,
      height: '50%',
      display: 'flex',
      boxShadow: `0 1px 1px rgba(0,0,0,0.12),
              0 2px 2px rgba(0,0,0,0.12),
              0 4px 4px rgba(0,0,0,0.12),
              0 8px 8px rgba(0,0,0,0.12),
              0 16px 16px rgba(0,0,0,0.12)`,
      overflow: 'hidden',
      width: '520px',
      maxWidth: '50%',
      cursor: 'default',
    },
    chatHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: '#2F5496',
      color: 'white',
      height: '2em',
      padding: '8px 16px',
    },
  })
)

const Component = () => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const { firstName, lastName } = useSelector(state => state.user)
  const [chatVisible, setChatVisible] = useState(false)
  const [doGetChatToken, { loading, data, error }] = useLazyQuery(
    GET_CHAT_TOKEN,
    {
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    if (error) {
      dispatch(
        openDialog({
          type: 'error',
          title: 'System Error',
          props: {
            error,
          },
        })
      )
    }
  }, [error, dispatch])

  const initChat = async () => {
    doGetChatToken()
    setChatVisible(true)
  }

  const initials = `${firstName?.substr(0, 1)}${lastName?.substr(0, 1)}`

  return (
    <>
      <IconButton
        data-testid='chatbot-open-button'
        className={classes.chatTrigger}
        onClick={initChat}
      >
        {!chatVisible && <FontAwesomeIcon icon={faCommentDots} />}
        {loading && <CircularProgress />}
      </IconButton>
      {chatVisible && data && (
        <div className={classes.chatContainer}>
          <Chatbot
            onCloseClick={() => setChatVisible(false)}
            initials={initials}
            token={data.chatbotToken}
          />
        </div>
      )}
    </>
  )
}

export default Component;