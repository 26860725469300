import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import ReactWebChat, { createDirectLine } from 'botframework-webchat'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { colors } from 'app/theme'

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 auto',
      '& a:link, a:visited, a:hover, a:active': {
        color: colors.saffron,
      },
    },
    chatHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: colors.calsawsBlue,
      color: colors.white,
      height: '2em',
      padding: '8px 16px',
    },
    closeButton: {
      color: colors.white,
      padding: 0,
    },
    chatContentContainer: {
      overflow: 'hidden'
    }
  })
)

const Chatbot = ({ onCloseClick, token, initials }) => {
  const classes = useStyles()

  const user = useSelector(state => state.user)

  const options = {
    avatarSize: 60,
    botAvatarBackgroundColor: colors.blue,
    botAvatarInitials: 'OCAT',
    userAvatarBackgroundColor: colors.grey,
    userAvatarInitials: initials,
    bubbleBackground: colors.blue,
    bubbleTextColor: colors.white,
    bubbleFromUserBackground: colors.grey,
    bubbleFromUserTextColor: colors.white,
    bubbleBorderRadius: 20,
    bubbleFromUserBorderRadius: 20,
    hideSendBox: true,
    bubbleNubSize: 3,
    bubbleNubOffset: 'top',
    bubbleFromUserNubSize: 3,
    bubbleFromUserNubOffset: 'top',
  }

  const store = window.WebChat.createStore(
    {},
    ({ dispatch }) => next => action => {
      if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
        dispatch({
          type: 'WEB_CHAT/SEND_MESSAGE',
          payload: {
            text: 'Hello O-Bot, I need some help.',
          },
        })
      }

      return next(action)
    }
  )

  const directLine = useMemo(
    () =>
      createDirectLine({
        token,
      }),
    [token]
  )

  return (
    <div data-testid='chatbot-frame' className={classes.container}>
      <div className={classes.chatHeader}>
        <div>Chat with O-Bot</div>
        <IconButton
          data-testid='chatbot-close-button'
          className={classes.closeButton}
          onClick={onCloseClick}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.chatContentContainer}>
      <ReactWebChat
        directLine={directLine}
        styleOptions={options}
        store={store}
        userID={user.email}
      />
      </div>
    </div>
  )
}

export default Chatbot;
